const Data = [
  {
    id: 1,
    icon: require("../../images/medyaplanlama.png"),
    header: "Medya Planlama ve Satın Alma",
    title:
      "Yerel ve ulusal medya kurumlarıyla iş birliği kurarak etkili kampanyalar yürütüyoruz.",
    text: "Reklam mesajı oluşturduktan sonra ilgili kitlenin mesaja en açık olduğu anda optimum iletişim maliyetiyle iletmektir. Medya  planlama ve satın alma, pazarlama iletişiminin en önemli süreçlerindendir.",
    longText:
      "Dem Medya, medya planlama ve satın amla konusunda Ankara'nın önde gelen firmalarından birisidir. Reklam süreçlerine hakimiyetiyle firmalar için en doğru, en etkili medya planlaması ve satın almasını gerçekleştirir ve süreçleri yönetir. Medya planlaması ve satın alma süreci söyle yönetilir;",

    servicesList: [
      "Firma/marka kampanya baslı pazarlama bütçesini ve hedefini belirler",
      "Firma/markanın pazarlama, satış satış hedeflerini analiz edilir.",
      "Analize bağlı kısa, orta, uzun vadeli strateji geliştirilir.",
      "Planlama için doğru medya mecraları belirlenir",
      "Her mecra için doğru zamanı belirlenir.",
      "Her mecra için doğru reklam ürünleri belirlenir.",
      "Pazarlama ve reklam çalışmaları için tasarımlar ve içerikleri hazırlanır.",
      "Planlama çerçevesinde medya satın alması yapılır.",
      "Satın alma sonuçları incelenir, analiz edilir.",
      "Sonuçlar raporlanır ve firma/markaya sunulur.",
    ],
    Video: require("../../videos/Yıldız.mp4"),
    Image: require("../../images/details/medya-planlama.png"),
  },
  {
    id: 2,
    icon: require("../../images/conception.png"),
    header: "Açık Hava Reklamcılığı",
    title:
      "Dem Medya olarak, Ankara’da açık hava reklamcılığının lider ajansı konumundayız.",
    text: "Açık hava reklamcılığı, dış mekanlarda her türlü trafiğin (yaya, ara. vb.) iletişimine açık noktalarda yaygın olarak kullanılan reklam yöntemidir. Kolay farkedilen ve akılda kalıcılığıyla açık havada hedef kitlesini yakalayan reklamlardır.",
    longText:
      "Dem Medya, Ankara’da açık hava reklamcılığının lider ajansı konumundadır. Ankara’da açık hava reklamcılığında uzun yıllardır hizmet sunmuştur.",
    servicesList: [
      "Billboard +",
      "Luna Billboard",
      "CLP Durak",
      "CLP Raket",
      "CLP Silindir Kule",
      "Billboard Plus",
      "Megalight (Afiş Değiştiricili-ADML)",
      "Megalight (Sabit-SML)",
      "KuleBoard",
      "MegaBoard",
      "Metro Billboard",
      "Metro CLP Raket",
      "Metro Sabit Megalight",
      "Metro Poster Pano",
      "Elektrik Direği Banner",
      "Köprü Alınlık",
    ],
    Video: require("../../videos/billboard.mp4"),
    Image: require("../../images/details/acik-hava.png"),
  },
  {
    id: 3,
    icon: require("../../images/socialmedia.png"),
    header: "Dijital Reklam ve Sosyal Medya",
    title:
      "Stratejik marka hedeflerinize göre dijital mecralar için yenilikçi reklamcılık çözümleri üretiyoruz.",
    text: "Sosyal medya mecralarında yayınlanan reklamlar doğru hedef kitle planlamasıyla güçlü ve doğru sonuçlara ulaşmayı sağlar. Sosyal medya paylaşımlarının devamlılığı marka için bilinirliği kalıcı hale getirir.",
    longText:
      "Dem Medya, şirketlerin/markaların sosyal medya hesaplarının içerik yönetimini üstlenerek hedef kitleleriyle düzenli iletişim ve bağ kurmalarını sağlar. Bu kapsamda, şirketler/markalar için içerik stratejisi geliştirir, şirket/marka temsilcileri ile güçlü bir işbirliği ile etkili içerikler geliştirir ve ilgil hesaplarda yayınlanır. Sponsorlu reklamlar ile avantaj elde edilir. Dem Medya, şirket ya da markalar için bir ya da birden fazla sosyal medya hesabı yönetimini sağlayabilir. İhtiyacınıza göre, Facebook, Instagram, Twitter, YouTube gibi mecralarda etkiletişiminizi arttırma, yakınınızdaki kişilere erişme, insanlarınızı websitenize çekme, gönderilerinizi öne çıkarma, sayfanızın tanıtımını yapma gibi reklam modelleri uygulanır. Bu reklam modelleri marka bilinirliğinizi arttırma, sayfanıza olan erişimi ve sayfa trafiğinizi arttırma, uygulamanızın yüklenmesini sağlama, görüntülenmelerinizi arttırma ve potansiyel müşteriler bulma şansınızı arttırır",
    servicesList: [
      "Sosyal Medya Yönetimi",
      "Sosyal Medya İçerik Yönetimi",
      "Google Adwords Reklamları",
      "Facebook Reklamları",
      "Instagram Reklamları",
      "Twitter Reklamları",
      "Youtube Reklamları",
      "Influencer Marketing",
      "Google My Business Kaydı",
    ],
    Video: require("../../videos/Dalga.mp4"),
    Image: require("../../images/details/sosyalmedya-01.png"),
  },
  {
    id: 4,
    icon: require("../../images/yazilim.png"),
    header: "Web Programlama",
    title:
      " Markanızın ihtiyacı olan web sayfası için yazılım çözümleri geliştiriyoruz.",
    text: "Üç ana web geliştirme türü vardır: front-end geliştirme, back-end geliştirme ve full-stack geliştirme. Front-end geliştiriciler, kullanıcı arayüzünde çalışan davranış ve görsellerden sorumluyken, back-end geliştiriciler sunucularla ilgilenir.",
    longText:
      "Günümüzde her marka/firmanın kesinlikle bir websitesi olması gerekiyor. Dem Medya, domain (alan adı), posting (web site barındırma) hizmeti verilir. Size uygun alan adını türkiye (.com.tr) ve global (.com) uzantılı seçeneklerle tescil edilir. Firmanız için kurumsal e-posta kullanabilmelerini sağlanır. Wordpress sistemi kullanarak seo’ya en uygun website tasarımları yapılır. Websitesinin güvenliği, sen ve SSL sertifikası kodlaması yapılarak firmanın onayına sunulur.",
    servicesList: [
      "domain",
      "hosting",
      "e-posta barındırma",
      "kurumsal web site tasarımı",
      "e-ticaret web site tasarımı",
    ],
    Video: require("../../videos/Spiral2.mp4"),
    Image: require("../../images/details/web-01.png"),
  },
  {
    id: 5,
    icon: require("../../images/identity.png"),
    header: "Kurumsal Kimlik",
    title: "Logonuzu, markanızı en iyi tanımlayacak şekilde tasarlıyoruz",
    text: "Bir markanın görsel kimliğidir. Kolay hatırlanabilir ve markayla özdeşmiş olmalırıd. Güçlü bir kurumsal kimlik bir gecede yaratılmaz. Markanızı gerçek anlamda yansıtan ve siz büyürken sizi desteklemeye devam edecek bir kimlik için; markanın kim olduğunun, ne yaptığının, markanızı dünyaya nasıl yansıtmak istediğinizin çok iyi şekilde anlaşılması, iletişim ve tasarım becerisi yüksek bir ekip gerekiyor.",
    longText:
      "Kurumsal kimlik markanın global yüzünü yansıtır. Markanın imajını sergiler. Markanın kim olduğu, ne yaptığı ve bu hizmeti nasıl verdiğinin anlatılmasını sağlar. Markanın felsefesini yansıtır. Şirketin prestijini yükseltmesi dışında rakipleri karşısında ve sektördeki yerini de belirler. Kurumsal kimlik nelerden oluşur?",
    servicesList: [
      "Logo",
      "Slogan",
      "Kurumsal Renk ve Font",
      "Kurumsal Kimlik",
      "Karvizit",
      "Antetli Kağıt, zarf, dosya, CD",
      "Muhasebe Dökümanları (form, fatura, irsaliye, makbuz)",
      "Şirket giyim kuralları",
      "E-posta Tasarımı",
      "Araç Tasarımları",
      "Bina cebhesi giydirme",
      "Gazete, dergi gibi tanıtım materyalleri tasarım kuralları",
      "Ambalaj kağıdı",
      "Promosyon Malzemeleri",
      "Takvim",
    ],
    Video: require("../../videos/Poster.mp4"),
    Image: require("../../images/details/kurumsal-01.png"),
  },
  {
    id: 6,
    icon: require("../../images/designer.png"),
    header: "Grafik Tasarım",
    title:
      "Markanızın ihtiyacı olan kreatif hizmetler dahilinde hedef kitlenize göre konsept tasarımlar oluşturuyoruz.",
    text: "Başarılı bir tasarım, özgün ve estetik olmasıyla, farkındalık yaratır ve mesajı nettir. Grafik tasarım, sorunları çözmek ve fikirleri tipografi, görüntü, renk ve form aracılığıyla iletmek için görsel kompozisyonların oluşturulmasıdır.",
    longText:
      "Grafik ve tasarımın tarihi, MÖ 14,000’lerde yapılmış mağara resimlerine ve İÖ 4. yüzyılda yazının başlamasına dayandırılabilir. Sonraları daha çok el yazması dini içerikli kitaplar ilk yayınlar olacaktır. Matbaanın icadı ile kitaplar yaygınlaşmaya başlamıştır. O dönem basılan dini kitaplardaki harf ve metin dizimi erken dönem grafik tasarım pratikleridir.Sanayileşme ve modern yaşama geçiş ve fotoğrafın keşfi ile gelişen grafik tasarım alanı günümüzde firma/markalar için vazgeçilmez bir reklam aracı olmuştur. Dem Medya’dan alabileceğiniz grafik hizmetleri şu şekildedir:",

    servicesList: [
      "Logo Tasarımı",
      "Katalog Tasarımı",
      "Afiş/Poster tasarımı",
      "Broşür Tasarımı",
      "Davetiye Tasarımı",
      "Menü ve fiyat listesi Tasarımı",
      "Stand Tsarımı",
      "Insert Tasarımı",
      "Flyer Tasarımı",
      "Faaliyet Raporu Tasarımı",
      "Kitap tasarımı",
      "Dergi Tasarımı",
      "E-Bülten Tasasrımı",
      "Banner Tasarımı",
      "Matbaa/Baskı Çözümleri",
    ],
    Video: require("../../videos/Kubik.mp4"),
    Image: require("../../images/details/grafik.png"),
  },
  {
    id: 7,
    icon: require("../../images/videography.png"),
    header: "Prodüksiyon ve Organizasyon",
    title:
      "Tüm fotoğraf ve video ekipmanlarımız ile organizasyonunuzu düzenliyoruz.",
    text: "Video prodüksiyon, internet üzerinde çeşitli dijital platformlar için video içeriği yapma sürecidir. Reklam ajansı olarak fotoğraf ve profesyonel video ekibimiz var. Yüksek kaliteli video prodüksiyonu için en yeni ekipmanlara sahibiz.",
    longText:
      "Tüm pazarlama süreçlerinin yanı sıra, bu tasarımlara temel oluşturacak fotoğraf, video gibi görsel unsurların profesyonel çekimlerini kaliteli bir şekilde hazırlanır. Profesyonel şekilde çekilmiş fotoğraf ve videolar markanın bilinirliğini ve kullanıcı etkileşimini nemli ölçüde arttırır. Nasıl çalışıyoruz? Profesyonel ekibimiz iş yerinizde kurulu portatif stüdyoda ürünlerinizin fotoğraf çekimini gerçekleştiriyor. Çekimler tamamlandıktan sonra fotoğrafları teslim ediyoruz. Hangi video prodüksiyon çözümlerini alabilirsiniz?",
    servicesList: [
      "Tanıtım filmi",
      "Reklam filmi",
      "Hava (drone) çekimleri",
      "Ürün tanıtım filmi",
      "İnfografik video yapımı",
      "Videopost (sosyal medya platformları için içeriğe uygun hazırlanan video gönderileri)",
      "Motion Graphics (hareketli görsel)",
      "Müşteri memnuniyet videoları",
      "Belgesel çekimi",
      "Youtube videoları",
      "Canlı yayın",
    ],
    Video: require("../../videos/Spiral.mp4"),
    Image: require("../../images/details/produksiyon.png"),
  },
  {
    id: 8,
    icon: require("../../images/baski.png"),
    header: "Baskı Hizmetleri ve Promosyon Ürünler",
    title:
      "Yaratıcı basılı ve promosyon ürünler markanızı daha etkili hale getirir.",
    text: "Basılı pazarlama yüzyıllardır var. peki neden dijital devrim sonrasında baskı sadece konumunu korumakla kalmayıp güçlendirdi; Çünkü baskı ürünleri somuttur bir broşür veya katalog kalıcıdır. Göz atmadan geçemeyeceğiniz ürünlerdir.",
    longText: "Dem Medya'dan alabileceğiniz baskı hizmetleri:",
    servicesList: [
      "Kartvizit",
      "Davetiye",
      "Katalog",
      "Afiş/Poster",
      "Broşür",
      "Menü ve Fiyat Listesi",
      "Stand",
      "Insert",
      "Flyer",
      "Faaliyet Raporu",
      "Antetli Kağıt",
    ],
    Video: require("../../videos/Baskı.mp4"),
    Image: require("../../images/details/baski-01.png"),
  },
  {
    id: 9,
    icon: require("../../images/radio.png"),
    header: "Radyo, Televizyon, Sinema Gazete ve Dergi",
    title:
      "Ürün yerleştirme, spot ve bant reklamı, radyo anonsu gibi birçok hizmet vermekteyiz.",

    text: "İnternetin yaygınlaşmasından önce baskın olarak kullanılan basın ve yayın araçları geneleksel medya olarak adlandırılır. Günümüzde de en etkili reklam araçlarından biridir. Akılda kalıcı, anlaşılır ve kalıcı bir reklam biçimi olması tv, radyo, gazete gibi yayın organlarının tercih sebebidir.",
    longText:
      "Dem Medya'dan alabileceğiniz tv, radyo, sinema reklamı hizmetleri:",
    servicesList: [
      "Düz Anons",
      "Diyalog ve Ses Efekti",
      "Müzikli yada Jingle Spotları",
      "Bant Reklam",
      "Spot Reklam",
      "Advertorial",
      "Ürün Yerleştirme",
      "Sponsorluk",
      "Stand Uygulaması",
      "Dijital Ekran Reklamları",
      "Raket",
      "Tuvalet Ayna Uygulaması",
      "Koltuk Giydirme/Sticker",
      "Fuaye Duvar",
      "Sütun giydirme",
      "Gişe Önü Özel Çalışma",
      "Popcorn Giydirme",
      "Bilet Cover Uygulaması",
      "Kreatif Uygulamalar",
      "Fuaye Roll Up-Cut/Out",
      "Anons",
    ],
    Video: require("../../videos/Makara.mp4"),
    Image: require("../../images/details/radyo-tv-02.png"),
  },
];

export default Data;
